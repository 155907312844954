import React from "react";
import Layout from "../../Partials/Layout/Layout";
import { Link } from "react-router-dom";
import Contact from "../../Section/Contact/Contact";
import { FaCheck } from "react-icons/fa";

const Impact = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description:
        " Elaboration des conventions, textes règlementaires et institutions en conformité avec le secteur d’activité du client            ",
    },
    {
      id: 3,
      description:
        "Exécution de l’étude sur site à base des questionnaires    ",
    },
    {
      id: 4,
      description:
        " Description du milieu biophysique, socio-économique et humain             ",
    },
    {
      id: 5,
      description:
        " Prélèvements des échantillons divers et analyse par un laboratoire agréé             ",
    },
    {
      id: 6,
      description:
        "Gestion des enquêtes socio-économiques et consultations publiques    ",
    },
    {
      id: 7,
      description: " Identification et évaluation des impacts potentiels  ",
    },
    {
      id: 8,
      description:
        " Définition des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs  ",
    },
    {
      id: 9,
      description:
        " Elaboration du plan de gestion environnementale et sociale   ",
    },
    {
      id: 10,
      description: " Production du rapport final  ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            L'Etude d'Impact Environnemental et Social :
          </span>{" "}
          Examen systématique visant à déterminer les effets favorables et
          défavorables susceptibles d’être causés, par un projet sur
          l’environnement. Elle permet d’atténuer, d’éviter, d’éliminer ou de
          compenser les effets néfastes sur l’Environnement.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          L'Etude d'Impact Environnemental et Social couvre les étapes  suivantes
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Audits = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description:
        " Vérification de la conformité des activités avec les règlements et conventions en vigueur dans le secteur d’activité du client        ",
    },
    {
      id: 3,
      description:
        "Exécution de l'audit sur site à base des questionnaires    ",
    },
    {
      id: 4,
      description:
        " Description du milieu biophysique, socio-économique et humain             ",
    },
    {
      id: 5,
      description:
        " Prélèvements des échantillons divers et analyse par un laboratoire agréé             ",
    },
    {
      id: 6,
      description:
        "Enquêtes socio-économiques et consultation publique avec les différentes parties prenantes     ",
    },
    {
      id: 7,
      description: " Identification et évaluation des impacts réels  ",
    },
    {
      id: 8,
      description:
        " Définition des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs  ",
    },
    {
      id: 9,
      description:
        " Elaboration du plan de gestion environnementale et sociale   ",
    },
    {
      id: 10,
      description: " Production du rapport final  ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Audits Environnementaux et Sociaux (AES) :
          </span>{" "}
          Evaluation systématique documentée et objective des activités d’une
          entité, d’une structure et des installations d’un établissement, de
          leur fonctionnement et de leur système de gestion environnementale en
          vue de s’assumer de la protection de l’environnement.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          L'AES concerne les entreprises déjà fonctionnelles et est constitué de la procédure suivante :
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Notice = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description:
        "Reconnaissance du site, collecte des données et évaluation de l’état des lieux",
    },
    {
      id: 3,
      description: "Enquête de voisinage",
    },
    {
      id: 4,
      description: "Identification et évaluation des impacts potentiels",
    },
    {
      id: 5,
      description:
        "Prescription des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs",
    },
    {
      id: 6,
      description:
        "Elaboration du cahier de charges environnementales  ",
    },
    {
      id: 7,
      description: "Production du rapport final",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Notice d’Impact Environnemental (NIE) :
          </span>{" "}
          Rapport établi au sujet des projets ou établissements/ installations
          de faible envergure qui ne sont pas assujettis à une étude d’impact
          environnemental et social ou à un audit environnemental et social,
          mais qui pourrait avoir des effets non négligeables sur
          l’environnement.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          Notice d’Impact Environnemental (NIE) couvre les étapes suivantes :
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Miseoeuvre = () => {
  const elements = [
    {
      id: 1,
      description:
        "Déployement du PGES ou CCE en plans d'action annuelle ",
    },
    {
      id: 2,
      description:
        "Elaboration de veille règlementaire HSE de l’entreprise",
    },
    {
      id: 3,
      description: "Accompagnement de la mise en conformité règlementaire de l’entreprise",
    },
    {
      id: 4,
      description: "Elaboration du plan de gestion des déchets et du registre des déchets",
    },
    {
      id: 5,
      description:
        "Evaluation la mise en œuvre effective des mesures environnementales contractuelles et de leur efficacité ",
    },
    {
      id: 6,
      description:
        "Détection de tout impact environnemental ou social imprévu pouvant se produire et actualisation du PGES ",
    },
    {
      id: 7,
      description:
        "Elaboration des rapports semestriels de mise en œuvre du PGES",
    },
    {
      id: 8,
      description:
        "Assistance lors des inspections conjointes des administrations ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Le Plan de Gestion Environnementale et Eociale (PGES) ou le Cahier de Charges Environnementales (CCE)  :
          </span>{" "}
          est le produit phare de l’évaluation environnementale (EE). C’est l’outil de planification de la mise en œuvre des mesures prescrites
          par l’EE. Son exécution correcte permet de maîtriser les impacts négatifs de l’intervention et de s’assurer qu’un projet s’insère harmonieusement dans son milieu d’accueil. Une fois l’EE réalisée et le PGES ou le CCE qui en découle approuvé, ces derniers deviennent des documents contractuels qui lient le
          promoteur à l’Administration chargée de l’Environnement ou à la Commune territorialement compétente.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          EDEC vous accompagne dans la mise en oeuvre des PGES ou CCE à travers les actions suivantes :
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Campagne = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence",
    },
    {
      id: 2,
      description:
        " Elaboration des conventions, textes règlementaires et institutions en conformité avec le secteur d’activité du client            ",
    },
    {
      id: 3,
      description:
        "Exécution de l’étude sur site à base des questionnaires    ",
    },
    {
      id: 4,
      description:
        " Description du milieu biophysique, socio-économique et humain             ",
    },
    {
      id: 5,
      description:
        " Prélèvements des échantillons divers et analyse par un laboratoire agréé             ",
    },
    {
      id: 6,
      description:
        "Gestion des enquêtes socio-économiques et consultation publique    ",
    },
    {
      id: 7,
      description: " Identification et évaluation des impacts potentiels  ",
    },
    {
      id: 8,
      description:
        " Définition des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs  ",
    },
    {
      id: 9,
      description:
        " Elaboration du plan de gestion environnementale et sociale   ",
    },
    {
      id: 10,
      description: " Production du rapport final  ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Campagne d’information et de sensibilisation communautaire
          </span>{" "}
          est l'élément clé au niveau du projet. Elle est l'examen systématique
          des facteurs environnementaux au niveau de l'élaboration des projets
          et de la prise de décision. Elle s'effectue avant toute prise de
          décision ou d'engagement important dans un projet.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          La Campagne d’information et de sensibilisation communautaire couvre
          tous les secteurs d’activité économique et est applicable à toute
          organisation qui souhaite :.
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Elaborationpermis = () => {
  const elements = [
    {
      id: 1,
      description: "Permis environnemental pour le tri, la collecte, le transport et l’élimination finale des déchets toxiques et/ou dangereux, déchets médicaux, pharmaceutiques, et déchets hospitaliers liquides  ",
    },
    {
      id: 2,
      description:
        "     Permis environnemental pour le tri, la collecte, le transport, le stockage, la valorisation, le recyclage, le traitement et l’élimination finale des déchets non dangereux et déchets ménagers liquides        ",
    },
    {
      id: 3,
      description:
        " Permis environnemental pour la fabrication, l’importation, la commercialisation ou la distribution des emballages non biodégradables  ",
    },
    {
      id: 4,
      description:
        " Permis environnemental pour la collecte, l’évacuation, le stockage, La valorisation, le recyclage, le traitement et l’élimination finale des déchets d’équipements électriques et électroniques   ",
    },

  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Permis environnemental :
          </span>{" "}
          Autorisation d’exercer une activité conforme à la réglementation environnementale en vigueur.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          Nous vous accompagnons dans l'élaboration des dossiers de demande  des permis environnemntaux suivants :
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs capitalizet">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Etudedanger = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description: `Collecte des données (Description et caractérisation de l’environnement, description des activités, du fonctionnement, de l’organisation et des moyens, description des procédés et des installations et recensement des matières premières, produits finis, produits intermédiaires et déchets)
      `,
    },
    {
      id: 3,
      description: `Traduction des données préalables à l’analyse des risques (analyse des accidents passés, identification et caractérisation des potentiels de danger, réduction des potentiels de danger)
      `,
    },
    {
      id: 4,
      description: `Analyse des risques (Evaluation préliminaire des risques, représentation cartographique et analyse détaillée des risques)  `,
    },
    {
      id: 5,
      description: `Caractérisation et maitrise des accidents majeurs potentiels.`,
    },
    {
      id: 6,
      description: `Production du rapport final
      `,
    },
    {
      id: 7,
      description: `Recenser les moyens internes et externes pour lutter contre un sinistre, catalogué par nature.
      `,
    },
    {
      id: 8,
      description: `Décrire l’organisation des secours pour chaque fonction en liaison avec les scénarii retenus dans l’étude de dangers
      `,
    },
    {
      id: 9,
      description: `Définir les modalités d’information des médias pour éviter les cas de panique éventuelle et expliquer les diverses mesures entreprises
      `,
    },
    {
      id: 10,
      description: `Définir les modalités de mise en œuvre des exercices d’entrainement qui permettent à chacun de se familiariser de la tâche qu’il aura à accomplis en cas de sinistre.
      `,
    },
    {
      id: 11,
      description: `Elaborer tous les documents complémentaires à la mise en œuvre du Plan d’Opérations Internes.
      `,
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">Etudes de dangers : </span> évaluation
          permettant d'apprécier les risques et dangers d'origine interne ou
          externe, induits par l'activité d’une unité de production afin de
          prévenir et de contrôler les accidents dans les établissements
          classés.
        </p>
      </div>
      <div className="pt-5">
        <p className="md:text-base text-xs text-black">
          <span className="font-semibold">Plans d’urgence : </span> Programme
          d'action propre à assurer l’alerte des autorités compétentes et des
          populations avoisinantes en cas de sinistre ou de menace de sinistre,
          l’évacuation du personnel et les moyens pour circonscrire les causes
          du sinistre.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-base  text-xs">
          Les étapes de réalisation de  l'Etude de danger et Plans d’urgence
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Elaborationdossier2 = () => {
  const elements = [
    {
      id: 1,
      description: "Obtention d’une autorisation d’implantation et d’exploitation d’un établissement classé dangereux, insalubre ou incommode de première classe ",
    },
    {
      id: 2,
      description:" Obtention d’une déclaration d’implantation et d’exploitation d’un établissement classé dangereux, insalubre ou incommode de deuxième classe ",
    },
    {
      id: 3,
      description: " Obtention d'une autorisation de prélèvement ou de déversement des eaux ",
    },
  
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-base text-xs text-black">
          <span className="font-semibold">
             Elaboration des dossiers de demandes d’autorisation ou de
            déclaration d’implantation et d’exploitation des établissements
            classés
          </span>{" "}

        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-base  text-xs">
          EDEC vous accompagne dans le respect des procédures règlementaires relatives au secteur industriel à travers:
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Elaborationdossier1 = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description:
        " Elaboration des conventions, textes règlementaires et institutions en conformité avec le secteur d’activité du client            ",
    },
    {
      id: 3,
      description:
        "Exécution de l’étude sur site à base des questionnaires    ",
    },
    {
      id: 4,
      description:
        " Description du milieu biophysique, socio-économique et humain             ",
    },
    {
      id: 5,
      description:
        " Prélèvements des échantillons divers et analyse par un laboratoire agréé             ",
    },
    {
      id: 6,
      description:
        "Gestion des enquêtes socio-économiques et consultation publique    ",
    },
    {
      id: 7,
      description: " Identification et évaluation des impacts potentiels  ",
    },
    {
      id: 8,
      description:
        " Définition des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs  ",
    },
    {
      id: 9,
      description:
        " Elaboration du plan de gestion environnementale et sociale   ",
    },
    {
      id: 10,
      description: " Production du rapport final  ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Elaboration des dossiers de demandes d’autorisation ou de
            déclaration d’implantation et d’exploitation des établissements
            classés
          </span>{" "}
          est l'élément clé au niveau du projet. Elle est l'examen systématique
          des facteurs environnementaux au niveau de l'élaboration des projets
          et de la prise de décision. Elle s'effectue avant toute prise de
          décision ou d'engagement important dans un projet.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          L'Elaboration des dossiers de demandes d’autorisation ou de
          déclaration d’implantation et d’exploitation des établissements
          classés couvre tous les secteurs d’activité économique et est
          applicable à toute organisation qui souhaite :.
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Formatiopro = () => {
  const elements = [
    {
      id: 1,
      description:
        " QHSE",
    },
    {
      id: 2,
      description:
        "Management, gestion d'entreprise",
    },
    {
      id: 3,
      description: "Sécretariat Bureautique ",
    },
    {
      id: 4,
      description:
        "Dévéloppement d'application ",
    },
    {
      id: 5,
      description:
        "Webmaster  ",
    },
    {
      id: 6,
      description:
        "Sécrétariat Comptable ",
    },
    {
      id: 7,
      description:
        "Maintenance des réseaux informatiques",
    },
    {
      id: 8,
      description:
        "Maintenance  informatiques ",
    },
    {
      id: 9,
      description: "Sécurité informatique ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">
            Formations
          </span>{" "}

        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">

        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

const Projet = () => {
  const elements = [
    {
      id: 1,
      description: "Elaboration des termes de référence  ",
    },
    {
      id: 2,
      description:
        " Elaboration des conventions, textes règlementaires et institutions en conformité avec le secteur d’activité du client            ",
    },
    {
      id: 3,
      description:
        "Exécution de l’étude sur site à base des questionnaires    ",
    },
    {
      id: 4,
      description:
        " Description du milieu biophysique, socio-économique et humain             ",
    },
    {
      id: 5,
      description:
        " Prélèvements des échantillons divers et analyse par un laboratoire agréé             ",
    },
    {
      id: 6,
      description:
        "Gestion des enquêtes socio-économiques et consultation publique    ",
    },
    {
      id: 7,
      description: " Identification et évaluation des impacts potentiels  ",
    },
    {
      id: 8,
      description:
        " Définition des mesures d’atténuation ou de bonification suivant les impacts négatifs ou positifs  ",
    },
    {
      id: 9,
      description:
        " Elaboration du plan de gestion environnementale et sociale   ",
    },
    {
      id: 10,
      description: " Production du rapport final  ",
    },
  ];
  return (
    <div className="lg:w-3/5  pl-5 ">
      <h3 className="text-2xl  font-semibold text-green-600">
        Etes-vous concerné ?
      </h3>
      <div className="pt-5">
        <p className="md:text-sm text-xs text-black">
          <span className="font-semibold">Gestion de projets</span> est
          l'élément clé au niveau du projet. Elle est l'examen systématique des
          facteurs environnementaux au niveau de l'élaboration des projets et de
          la prise de décision. Elle s'effectue avant toute prise de décision ou
          d'engagement important dans un projet.
        </p>
      </div>
      <div className="py-5 space-y-5  ">
        <p className="text-black md:text-sm  text-xs">
          La Gestion de projets couvre tous les secteurs d’activité économique
          et est applicable à toute organisation qui souhaite :.
        </p>
        <div className="space-y-3">
          {elements
            ? elements.map((element) => {
              return (
                <span className="flex flex-row lg:space-x-5  space-x-2 items-center lg:text-base text-xs">
                  <FaCheck size={12} />{" "}
                  <span key={element.id} className="w-4/5">
                    {" "}
                    {element.description}
                  </span>{" "}
                </span>
              );
            })
            : null}
          {/* <FaCheckCircle className='hidden lg:block' size={10} /> */}
        </div>
      </div>
    </div>
  );
};

function Audit() {
  const location = document.location.pathname;

  const domaines = [
    {
      id: 1,
      description: "Etudes d’Impact Environnemental et Social (EIES)  ",
      path: "/etudeimpact",
    },
    {
      id: 2,
      description: " Audits Environnementaux et Sociaux (AES)    ",
      path: "/audit",
    },
    {
      id: 3,
      description: "Notice d’Impact Environnemental (NIE)  ",
      path: "/notice",
    },
    {
      id: 4,
      description:
        " Mise en œuvre des Plans de Gestion Environnementale et Sociale (PGES) ou du Cahier de charges environnementales (CCE)    ",
      path: "/miseoeuvre",
    },
    // {
    //   id: 5,
    //   description:
    //     " Campagne d’information et de sensibilisation communautaire    ",
    //   path: "/campagne",
    // },
    {
      id: 5,
      description: " Élaboration des permis environnementaux ",
      path: "/elaborationpermis",
    },
    {
      id: 6,
      description: "Etudes de dangers et Plans d’urgence   ",
      path: "/etudedanger",
    },
    // {
    //   id: 7,
    //   description:
    //     "Elaboration des dossiers de demandes d’autorisation ou de déclaration d’implantation et d’exploitation des établissements classés  ",
    //   path: "/elaborationdossier1",
    // },
    {
      id: 8,
      description:
        "Elaboration des dossiers de demandes d’autorisation de prélèvement ou de déversements des eaux  ",
      path: "/elaborationdossier2",
    },
    {
      id: 9,
      description:
        "Formations",
      path: "/formationprofession",
    },
    // {
    //   id: 10,
    //   description: "Gestion de projets ",
    //   path: "/gestionprojet",
    // },
  ];

  return (
    <Layout
      title={
        location === "/etudeimpact"
          ? "Etudes d’Impact Environnemental et Social  "
          : location === "/audit"
            ? "Audits Environnementaux et Sociaux (AES)"
            : location === "/notice"
              ? "Notice d’Impact Environnemental (NIE)"
              : location === "/miseoeuvre"
                ? "Mise en œuvre des Plans de Gestion Environnementale et Sociale (PGES)"
                : location === "/campagne"
                  ? "Campagne d’information et de sensibilisation communautaire"
                  : location === "/elaborationpermis"
                    ? "Élaboration des permis environnementaux"
                    : location === "/etudedanger"
                      ? "Etudes de dangers et Plans d’urgence"
                      : location === "/elaborationdossier1"
                        ? "Elaboration des dossiers de demandes d’autorisation ou de déclaration d’implantation et d’exploitation des établissements classés"
                        : location === "/elaborationdossier2"
                          ? "Elaboration des dossiers de demandes d’autorisation de prélèvement ou de déversements des eaux"
                          : location === "/formationprofession"
                            ? "Formation professionnelle Qualité Hygiène Sécurité et Environnementale (QHSE)"
                            : location === "/gestionprojet"
                              ? "Gestion de projets"
                              : null
      }
    >
      <div className="w-full   flex lg:flex-row flex-col w-full section  container_fluid relative ">
        <div className="lg:w-2/5 lg:border-r lg:border-green-600 lg:block hidden">
          <div className="h-16">
            <h3 className="text-2xl underline font-semibold">
              Domaines de Competence
            </h3>
          </div>
          <div className="w-full  flex flex-col gap-2 relative pr-12">
            {domaines
              ? domaines.map((domaine) => {
                return domaine.path === location ? (
                  <Link
                    to={domaine.path}
                    className="w-full p-3 bg-green-600 text-white text-xs rounded-lg"
                    key={domaine.id}
                  >
                    {domaine.description.substring(0, 60)}{" "}
                    {domaine.description.length <= 60 ? "" : "..."}
                  </Link>
                ) : (
                  <Link
                    to={domaine.path}
                    className="w-full p-3 bg-transparent border-2 border-green-600 text-xs rounded-lg"
                    key={domaine.id}
                  >
                    {domaine.description.substring(0, 60)}{" "}
                    {domaine.description.length <= 60 ? "" : "..."}
                  </Link>
                );
              })
              : null}
          </div>
        </div>
        {location === "/etudeimpact" ? (
          <Impact />
        ) : location === "/audit" ? (
          <Audits />
        ) : location === "/campagne" ? (
          <Campagne />
        ) : location === "/etudedanger" ? (
          <Etudedanger />
        ) : location === "/elaborationdossier1" ? (
          <Elaborationdossier1 />
        ) : location === "/elaborationpermis" ? (
          <Elaborationpermis />
        ) : location === "/elaborationdossier2" ? (
          <Elaborationdossier2 />
        ) : location === "/formationprofession" ? (
          <Formatiopro />
        ) : location === "/gestionprojet" ? (
          <Projet />
        ) : location === "/miseoeuvre" ? (
          <Miseoeuvre />
        ) : location === "/notice" ? (
          <Notice />
        ) : null}
      </div>
      {/* <Contact /> */}
    </Layout>
  );
}

export default Audit;
