import React from "react";
import ServiceCard from "../../Component/ServiceCard";
import Title from "../../Component/Title";

function Service() {
  const list_service = [
    {
      id: 1,
      image_service: "",
      service_title: "Etudes d’Impact Environnemental et Social (EIES)",
      service_desription: "",
      path: "/etudeimpact",
    },
    {
      id: 2,
      image_service: "",
      service_title: "Audits Environnementaux et Sociaux (AES)",
      service_desription: "",
      path: "/audit",
    },
    {
      id: 3,
      image_service: "",
      service_title: "Notice d'Impact Environnemental (NIE)",
      service_desription: "",
      path: "/notice",
    },
    {
      id: 4,
      image_service: "",
      service_title:
        "Mise en œuvre des Plans de Gestion Environnementale et Sociale (PGES) ou du Cahier de charges environnementales (CCE) ",
      service_desription: "",
      path: "/miseoeuvre",
    },
    {
      id: 5,
      image_service: "",
      service_title:
        "Campagne d'information et de sensibilisation communautaire",
      service_desription: "",
      path: "/campagne",
    },
    {
      id: 6,
      image_service: "",
      service_title: "Elaboration des permis environnementaux",
      service_desription: "",
      path: "/elaborationpermis",
    },
    {
      id: 7,
      image_service: "",
      service_title: "Etudes de dangers et Plans d'urgence",
      service_desription: "",
      path: "/etudedanger",
    },
    {
      id: 8,
      image_service: "",
      service_title:
        "Elaboration des dossiers de demandes d'autorisation ou de déclaration d'implantation et d'exploitation des établissement classés",
      service_desription: "",
      path: "/elaborationdossier2",
    },

    // {
    //   id: 9,
    //   image_service: "",
    //   service_title:
    //     "Elaboration des dossiers de demandes d'autorisation de prélèvement ou de déversements des eaux",
    //   service_desription: "",
    //   path: "/elaborationdossier2",
    // },
    {
      id: 10,
      image_service: "",
      service_title:
        "Formations",
      service_desription: "",
      path: "/formationprofession",
    },
    // {
    //   id: 11,
    //   image_service: "",
    //   service_title: "Gestion de projet",
    //   service_desription: "",
    //   path: "/gestionprojet",
    // },
  ];

  return (
    <div className=" bg-white section flex flex-row  w-full justify-center w-full">
      <div className="container_fluid w-full  ">
        <div className=" ">
          <div className="title">
            <Title
              title="Nous offrons plusieurs services"
              description="Services de qualité à la porté de tous"
            />
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {list_service.map((service) => {
              return (
                <ServiceCard
                  path={service.path}
                  key={service.id}
                  image_service={service.image_service}
                  service_title={service.service_title}
                  service_desription={service.service_desription}
                />
              );
            })}
          </div>
          <div>
            {/* <div className="text-center pt-8">
              <button
                onClick={() => window.open("/service", "_self")}
                className="animation ease-in-out duration-300  bg-green-500 border-2 hover:border-2 hover:border-green-600 hover:bg-transparent hover:text-black-600 rounded-md p-3 font-regular text-sm"
              >
                Voir Plus
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
