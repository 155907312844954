import React from "react";

function Newsletter() {
  return (
    <div className="w-full py-0">
      <div className="container_fluid bg-green-600 h-48  flex md:flex-row flex-col items-center gap-4 md:gap-0 justify-center md:justify-between">
        <div className="flex flex-col gap-3 text-center">
          <h2 className="lg:text-4xl md:font-bold uppercase md:text-xl text-white mt-3 md:mt-0 md:text-left text-center">
            Etes vous interessés par nos services ?
          </h2>
          <span className="text-gray-200 md:text-left text-center">
            Contactez nous pour plus de détails
          </span>
        </div>
        <div>
          <button
            onClick={() => window.open("/contact", "_self")}
            className="animation ease-in-out duration-300  bg-green-600 border-2 text-white  hover:bg-white hover:text-black rounded-full py-3 px-5 font-regular text-sm"
          >
            Contactez-Nous
          </button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
