import React, { useState } from "react";
import Title from "../../Component/Title";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, telephone, message };

    alert(JSON.stringify(data));
  };

  return (
    <div className="section bg-gray-50 relative">
      <div className="container_fluid flex flex-col mx-auto">
        <Title
          title="Rejoignez EDEC dès aujourd'hui"
          description="Contactez-nous pour trouver la bonne solution pour votre entreprise"
        />
        <div className="w-full md:h-96 h-screen flex md:flex-row flex-col md:space-y-0 space-y-8">
          <form
            className="md:w-1/2  w-full h-full flex flex-col md:pr-5 space-y-5"
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="p-4 text-sm border-2 border-gray-100 focus:outline-none animation ease-out duration-600 focus:border-green-200"
              placeholder="Votre nom"
              required
            />
            <input
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              className="p-4 text-sm border-gray-100 border-2 focus:outline-none animation ease-out duration-600 focus:border-green-200"
              placeholder="Votre numéro"
              required
            />
            <input
              type="email"
              className="p-4 text-sm border-gray-100 border-2 focus:outline-none animation ease-out duration-600 focus:border-green-200"
              placeholder="Votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              name=""
              id=""
              cols="10"
              rows="5"
              className="p-4 text-sm border-2 border-gray-100 focus:outline-none animation ease-out duration-600 focus:border-green-200"
              placeholder="Ecrivez votre message ici ..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            >
              {message}
            </textarea>
            <button
              type="submit"
              className="bg-green-500 p-4 text-sm text-white font-semibold border-gray-100"
            >
              Envoyer
            </button>
          </form>
          <div className="md:w-1/2  w-full  h-full">
            <div className="mapouter relative text-right h-full w-full">
              <div className="h-full w-full">
                <iframe
                  title="EDEC Localisation"
                  className="h-full w-full"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=douala-beddi-saker&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://www.embedgooglemap.net">Douale-Beedi</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
