import React from "react";
import Title from "../../Component/Title";
import { IMAGES } from "../../constant/images";
import ValeurCard from "../../Component/ValeurCard";

function Valeur() {
  return (
    <div className="flex justify-center">
      <div className="container_fluid section">
        <Title
          title="Pourquoi nous choisir ?"
          description="Nous allions droit, legalité et professionalisme pour vous servir"
        />
        <div
          className="grid md:grid-cols-3 gap-4"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <ValeurCard
            icon={IMAGES.DROIT}
            title="Vision"
            description=" Être un Cabinet d’Ingénierie en Q.H.S.E d’excellence offrant des prestations 
            de qualité en matière d’études ... "
          />
          <ValeurCard
            icon={IMAGES.DROIT}
            title="Mission"
            description=" Contribuer activement à
             l’émergence d’un développement durable au Cameroun ...

            "
          />
          <ValeurCard
            icon={IMAGES.DROIT}
            title="Consultant"
            description="Le bureau repose sur un noyau de personnel permanent appuyés par des 
            Consultants extérieurs qualifiés ..."
          />
        </div>
      </div>
    </div>
  );
}

export default Valeur;
