import React from "react";
import Layout from "../../Partials/Layout/Layout";
import Service from "../../Section/Services/Service";

function Services() {
  return (
    <Layout title="Nos Différents Services">
      <div>
        <Service />
      </div>
    </Layout>
  );
}

export default Services;
