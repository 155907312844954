import React from "react";

function ValeurCard({ icon, title, description }) {
  return (
    <div className="animation ease-in-out duration-300 flex flex-row bg-white cursor-pointer p-8 rounded-md border-2 hover:drop-shadow-lg hover:shadow-red-800">
      <div className="md:w-1/5 hidden md:block">
        <img src={icon.default} width="50%" alt="" />
      </div>
      <div className="flex flex-col md:w-4/5">
        <span className="font-semibold">{title}</span>
        <span>{description}</span>
      </div>
    </div>
  );
}

export default ValeurCard;
