import React from "react";
import SmallSection from "../../Section/SmallSection/SmallSection";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Newsletter from "../../Component/Newsletter";
import Contact from "../../Section/Contact/Contact";

function Layout({ title, children }) {
  return (
    <>
      <Navbar />
      <SmallSection title={title} />
      {children}
      <Newsletter />
      <Contact />
      <Footer />
    </>
  );
}

export default Layout;
