import React from "react";
// import Navbar from "../../Partials/Navbar/Navbar";
import "./Home.css";
import HeroSection from "../../Section/HeroSection/HeroSection";
import Hero2 from "../../Section/HeroSection/Hero2";
import Partner from "../../Section/Partner/Partner";
import Service from "../../Section/Services/Service";
import Footer from "../../Partials/Footer/Footer";
import Valeur from "../../Section/Valeur/Valeur";
import Contact from "../../Section/Contact/Contact";
import Newsletter from "../../Component/Newsletter";
import Agreement from "../../Component/Agreement";

function Home() {
  return (
    <div className="home">
      <Hero2 />
      {/* <HeroSection /> */}
      <Agreement />
      <Valeur />
      <Service />
      <Partner />
      <Newsletter />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
