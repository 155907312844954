import React from "react";

function Title({ title, description }) {
  return (
    <div className="text-center pb-9 flex flex-col gap-1 space-y-2 ">
      <span className="text-4xl font-semibold uppercase">{title}</span>
      <div className="h-1 w-24 bg-green-600 mx-auto"></div>
      <p>{description}</p>
    </div>
  );
}

export default Title;
