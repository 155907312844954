import React from "react";
import NetCard from "../../Component/NetCard";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import edec from "../../assets/images/edec.png";

import "./Footer.css";

function Footer() {
  return (
    <div className="container_footer">
      <div className="top flex flex-row lg:pt-16 pt-5">
        <div className="lg:w-2/5 w-full space-y-2 pr-16 lg:space-y-12 ">
          <div className="flex flex-row lg:space-x-5 items-center ">
            <img src={edec} className="lg:w-14 w-12 lg:h-12" alt="" />
            <p className="md:text-5xl text-2xl font-semibold text-green-500 text-center">
              EDEC
            </p>
          </div>

          <p className="text-white lg:text-base md:text-sm text-xs">
            {" "}
            Environment Development Engineering Consulting. <br />
            Est une Société qui fait dans l'évaluation environnementale
          </p>
        </div>
        <div className="w-1/5 lg:space-y-16 space-y-2">
          <p className="lg:text-xl md:text-base text-sm font-semibold text-white lg:mt-0 mt-2">
            A propos
          </p>
          <ul className="text-white lg:text-base md:text-sm text-xs flex flex-col lg:space-y-6 space-y-1">
            <Link to="/">Accueil</Link>
            <Link to="/presentation">Présentation</Link>
            <Link to="/service">Services</Link>
            <Link to="/realisation">Réalisations</Link>
            <Link to="/contact">Contact</Link>
          </ul>
        </div>
        <div className="lg:w-1/5  lg:space-y-16 space-y-2">
          <p className="text-xl font-semibold text-white lg:mt-0 mt-2 md:text-base text-sm">
            Contact
          </p>
          <ul className="lg:text-base md:text-sm  lg:space-y-2 space-y-2 text-xs flex flex-col text-white">
            <li>
              Tél . :{" "}
              <span>
                698 576 620 <br />
                233 370 208
              </span>
            </li>
            <li>Beedi(Immeuble Saker, 3ème étage)</li>
            <li>
              Email :{" "}
              <span className="text-green-600">
                {" "}
                cabinet_edec@edec-engineering.com
              </span>
            </li>
            <li>BP : 24145 Douala-Cameroun</li>
          </ul>
        </div>
        <div className="w-1/5 lg:space-y-16 space-y-2 flex md:flex-col flex-row space-x-2 md:space-x-0">
          <p className="text-xl font-semibold text-white lg:mt-0 mt-2 md:text-base text-sm">
            Liens
          </p>
          <div className="flex flex-row space-x-3">
            <NetCard icon={<FaFacebook color="white" size={21} />} />
            <NetCard icon={<FaTwitter color="white" size={21} />} />
            <NetCard icon={<FaWhatsapp color="white" size={21} />} />
            <NetCard icon={<FaLinkedin color="white" size={21} />} />
          </div>
        </div>
      </div>
      <div className="bottom border-t border-green-500 flex flex-col relative">
        <div className="w-full flex md:flex-row flex-col pt-5  space-y-2 md:space-y-0 ">
          <div className="md:w-2/5 w-full flex items-center">
            <p className="text-white font-bold md:text-base text-xs">
              REJOIGNEZ NOTRE NEWSLETTER
            </p>
          </div>
          <div className="md:w-3/5 flex flex-row">
            <input
              type="email"
              placeholder="Entrer votre Email"
              className="w-2/3 p-3 text-xs md:text-base focus:outline-none"
            />
            <button
              type="submit"
              className="w-1/3 bg-green-600 hover:bg-green-700 text-white font-semibold text-xs md:text-base"
            >
              ENVOYER
            </button>
          </div>
        </div>
        <div className="flex md:flex-row md:absolute w-full bottom-3 space-y-2 md:space-y-0 md:mt-0 mt-2  ">
          <p className="text-white text-xs ">
            Tous droits réservés @ 2023. Développé par Nylstech Digital
          </p>
          <p className="absolute md:right-0 md:bottom-0 bottom-2 text-white text-xs ">
            Termes & Conditions
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
