import React from "react";
import "./Component.css";

function Partner_card(props) {
  return (
    <div className="p-2 bg-white flex justify-center items-center cursor-pointer">
      <img
        src={props.img}
        alt=""
        width={80}
        className="animation ease-in-out duration-300 h-20 hover:scale-110"
      />
    </div>
  );
}

export default Partner_card;
