import React from "react";
import styles from "./smallsection.module.css";
import { FaChevronRight } from "react-icons/fa";

function SmallSection(props) {
  return (
    <div className={`${styles.small_section} h-[50vh]  pt-10 `}>
      <div className="w-full h-full flex flex-col">
        <div className=" flex flex-column items-center h-2/3">
          <h2
            className=" md:text-5xl mt-24 text-3xl text-white font-semibold container_fluid mx-auto text-center"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            {props.title.substring(0, 50)}{" "}
            {props.title.length <= 60 ? "" : "..."}
          </h2>
        </div>
        <div className="w-full container_fluid text-center h-1/3">
          <div
            className="md:-mt-2 -mt-4 flex gap-2 flex-row items-center justify-center  md:pl-10 font-semibold text-white md:text-base text-xs"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Accueil
            <FaChevronRight size={10} />
            <span className="text-green-300">
              {props.title.substring(0, 50)}{" "}
              {props.title.length <= 60 ? "" : "..."}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallSection;
