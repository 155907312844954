import React, { useState } from "react";
import { Link } from "react-router-dom";
import edec from "../../assets/images/edec.png";
import "./Navbar.css";

function Navbar() {
  const [button, setButton] = useState(true);
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(button === true ? false : true);
    }
  };

  const links = [
    {
      name: "Accueil",
      link: "/",
    },
    {
      name: "Présentation",
      link: "/presentation",
    },
    {
      name: "Services",
      link: "/service",
    },
    {
      name: "Réalisations",
      link: "/realisation",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  const path = window.location.pathname;

  return (
    <div className="navbar_section bg-black md:bg-green-600 fixed top-0 flex flex-col justify-center">
      <div className="container_navbar flex gap-0">
        <div className="content_logo">
          <img src={edec} alt="" />
        </div>
        <nav
          className={
            !button ? "menu_list  menu_list2 " : "menu_list menu_list1"
          }
        >
          <ul>
            {links.map((item) => {
              return (
                <li className="" key={item.name}>
                  <Link
                    to={item.link}
                    className={`link ${
                      path === item.link
                        ? "md:bg-white text-black"
                        : "text-white"
                    } p-4 animation duration-200 md:hover:bg-white md:hover:text-gray-900`}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className="drop_button">
          <>
            <input
              type="checkbox"
              id="hamburgerEffect"
              onClick={showButton}
            ></input>
            <label id="burger" for="hamburgerEffect">
              <div></div>
              <div></div>
              <div></div>
            </label>
          </>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
