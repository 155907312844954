import React from "react";
import { Link } from "react-router-dom";
import edec from "../assets/images/edec.png";
import "./Component.css";
import { useNavigate } from "react-router-dom";

function ServiceCard(props) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => window.open(props.path, "_self")}
      className="service__card drop-shadow-md bg-white cursor-pointer"
    >
      <div className="service__card__content"></div>
      <div className="services__cards__text">
        <div className="service__card_rounded__image">
          <div className="rounded__image">
            <img src={edec} className="w-10" alt="" />
          </div>
        </div>
        <div className="service__card_content__text flex flex-col gap-5">
          <div className="h-3/5">
            <h1 className="text-1xl font-semibold">
              {props.service_title.substring(0, 70)}{" "}
              {props.service_title.length <= 60 ? "" : "..."}
            </h1>
            <p>{props.service_desription}</p>
          </div>
          <div className="h-2/5">
            <div className="font-regular hover:text-gray-100">Consulter </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
