import React from 'react'

function NetCard(props) {
  return (
    <div className='netcard  opacity-90'>
          <span className='icon'>  {props.icon}</span>
    </div>
  )
}

export default NetCard