import React from "react";
import Layout from "../../Partials/Layout/Layout";
import Partner from "../../Section/Partner/Partner";

function Contacts() {
  return (
    <Layout title="Contactez-nous">
      {/* <Contact /> */}
      <Partner />
    </Layout>
  );
}

export default Contacts;
