import React from "react";
import Partner_card from "../../Component/Partner_card";
import partner1 from "../../assets/images/partner1.jpg";
import partner2 from "../../assets/images/partner2.jpg";
import partner3 from "../../assets/images/partner3.jpg";
import partner4 from "../../assets/images/partner4.jpg";
import partner6 from "../../assets/images/partner6.jpg";
import partner7 from "../../assets/images/partner7.png";
import partner8 from "../../assets/images/partner8.png";
import partner9 from "../../assets/images/partner9.png";
import partner10 from "../../assets/images/partner10.png";
import partner11 from "../../assets/images/partner11.png";
import partner12 from "../../assets/images/partner12.png";
import partner13 from "../../assets/images/partner13.png";
import partner14 from "../../assets/images/partner14.jpg";
import partner15 from "../../assets/images/partner15.png";
import Title from "../../Component/Title";
import Marquee from "react-fast-marquee";

function Partner() {
  const list1 = [
    {
      id: 1,
      logo: partner1,
    },
    {
      id: 2,
      logo: partner2,
    },
    {
      id: 3,
      logo: partner3,
    },
    {
      id: 4,
      logo: partner4,
    },
    {
      id: 5,
      logo: partner6,
    },
  ];

  const list2 = [
    {
      id: 1,
      logo: partner7,
    },
    {
      id: 2,
      logo: partner8,
    },
    {
      id: 3,
      logo: partner9,
    },
    {
      id: 4,
      logo: partner10,
    },
    {
      id: 6,
      logo: partner11,
    },
  ];

  const list3 = [
    {
      id: 1,
      logo: partner12,
    },
    {
      id: 2,
      logo: partner13,
    },
    {
      id: 3,
      logo: partner14,
    },
    {
      id: 4,
      logo: partner15,
    },
    {
      id: 5,
      logo: partner10,
    },
  ];

  return (
    <div className="bg-white flex justify-center">
      <div className="container section">
        <Title
          title="ILS NOUS ONT FAIT CONFIANCE"
          description=""
        />
        <Marquee speed={70} className="mb-3">
          <div className="w-full grid grid-cols-5 gap-2 md:gap-9 ">
            {list1.map((list) => {
              return <Partner_card key={list.id} img={list.logo}  className=""/>;
            })}
          </div>
        </Marquee>

        <Marquee speed={50} direction="right" delay={2} className="mb-5">
          <div className="w-full grid grid-cols-5 gap-2 md:gap-9 ">
            {list2.map((list) => {
              return <Partner_card key={list.id} img={list.logo} />;
            })}
          </div>
        </Marquee>

        <Marquee speed={50} direction="left">
          <div className="w-full grid grid-cols-5 gap-2 md:gap-9 ">
            {list3.map((list) => {
              return <Partner_card key={list.id} img={list.logo} />;
            })}
          </div>
        </Marquee>
      </div>
    </div>
  );
}

export default Partner;
