import React from "react";
import "./Hero.scss";
import Navbar from "../../Partials/Navbar/Navbar";

function Hero2() {
  return (
    <div className="relative h-[90vh] overflow-hidden">
      <Navbar />
      <div className="relative">
        <div className="slider-one">
          <div className="slider-one-image">
            <div className="slider-text md:w-3/5 flex flex-col gap-6">
              <h1 className="leading-[37px] md:leading-[55px] text-[1.6rem] md:text-[2.7rem] normal-case">
                EDEC,votre partenaire de référence pour une transition réussie
                vers un développement durable
              </h1>
              <p className="text-gray-300 pr-12">
                Environment Development Engineering Consulting (EDEC), Nous
                mettons nos experts à votre disposition afin de vous fournir des
                services de qualité.
              </p>
            </div>
          </div>
        </div>
        <div className="slider-two">
          <div className="slider-two-image">
            <div className="slider-text md:w-3/5 flex flex-col gap-6">
              <h1 className="leading-[37px] md:leading-[55px] text-[1.6rem] md:text-[2.7rem] normal-case">
                Une equipe de professionnels pour vous fournir le meilleur
                service possible et terme de Q.H.S.E
              </h1>
              <p className="text-gray-300 pr-12">
                Environment Development Engineering Consulting (EDEC), Nous
                mettons nos experts à votre disposition afin de vous fournir des
                services de qualité.
              </p>
            </div>
          </div>
        </div>
        <div className="slider-three">
          <div className="slider-three-image">
            <div className="slider-text md:w-3/5 flex flex-col gap-6">
              <h1 className="leading-[37px] md:leading-[55px] text-[1.6rem] md:text-[2.7rem] normal-case">
                Nous contribuons activement à l’émergence d’un développement
                durable au Cameroun et au delà
              </h1>
              <p className="text-gray-300 pr-12">
                Environment Development Engineering Consulting (EDEC), Nous
                mettons nos experts à votre disposition afin de vous fournir des
                services de qualité.
              </p>
            </div>
          </div>
        </div>
        <div className="slider-four">
          <div className="slider-four-image">
            <div className="slider-text md:w-3/5 flex flex-col gap-6">
              <h1 className="leading-[37px] md:leading-[55px] text-[1.6rem] md:text-[2.7rem] normal-case">
                Un cabinet d’Ingénierie en Q.H.S.E d’excellence offrant des
                prestations de qualité en matière d’études
              </h1>
              <p className="text-gray-300 pr-12">
                Environment Development Engineering Consulting (EDEC), Nous
                mettons nos experts à votre disposition afin de vous fournir des
                services de qualité.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero2;
