import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Notfound from "./Pages/NotFound/Notfound";

import AOS from "aos";
import "aos/dist/aos.css";
import Presentation from "./Pages/Presentation/Presentation";
import Contacts from "./Pages/Contact/Contacts";
import Audit from "./Pages/Audit/Audit";
import Services from "./Pages/Service/Services";
import Realisation from "./Pages/Realisation/Realisation";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/etudeimpact" element={<Audit />} />
          <Route path="/notice" element={<Audit />} />
          <Route path="/audit" element={<Audit />} />
          <Route path="/miseoeuvre" element={<Audit />} />
          <Route path="/campagne" element={<Audit />} />
          <Route path="/elaborationpermis" element={<Audit />} />
          <Route path="/etudedanger" element={<Audit />} />
          <Route path="/elaborationdossier1" element={<Audit />} />
          <Route path="/elaborationdossier2" element={<Audit />} />
          <Route path="/formationprofession" element={<Audit />} />
          <Route path="/gestionprojet" element={<Audit />} />
          <Route path="/service" element={<Services />} />
          <Route path="/realisation" element={<Realisation />} />
          <Route path="*" exact={true} element={<Notfound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
