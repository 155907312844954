import React from "react";
import { FaCheese } from "react-icons/fa";

function Agreement(props) {
  const agreement = [
    {
      label:
        "Ministère de l’Environnement, de la Protection de la Nature et du développement durable (MINEPDED) pour la réalisation des évaluations environnementales ;",
    },
    {
      label: `Ministère des Mines, de l’Industrie et du Développement
            Technologique (MINMIDT) pour la réalisation des Etudes de Dangers
            et Plans d’Urgence ;`,
    },
    {
      label: `Ministre de l'Emploi et de la Formation Professionnelle pour les
        Formations QHSE.`,
    },
  ];
  return (
    <div className="relative w-full flex items-center container_fluid  h-28 translate-y-8 justify-center mb-[100px] md:mb-[50px]">
      <div className="p-10 bg-white md:absolute bottom-0 rounded-lg py-5 shadow-sm w-full  md:w-4/5  border border-green-600">
        <h1 className="font-semibold uppercase">Agréé par :</h1>
        <div className="text-black flex flex-col gap-6 mt-4">
          {/* Agreement list */}
          {agreement &&
            agreement.map((item, key) => {
              return (
                <div className="flex flex-row items-center gap-3" key={key}>
                  <span>
                    <FaCheese size={15} color="green" className="text-black" />
                  </span>
                  <span className="text-[12px] md:text-[15px]">
                    {item.label}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Agreement;
