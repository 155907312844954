import React from "react";
import Layout from "../../Partials/Layout/Layout";
import edec from "../../assets/images/edec.png";
import real1 from "../../assets/images/real1.jpeg";
import Partner from "../../Section/Partner/Partner";
import { FaChevronRight } from "react-icons/fa";

function Presentation() {
  return (
    <Layout title="Présentation">
      <div>
        <div className="container_fluid section flex flex-row items-center">
          <div className="w-full mx-auto">
            <div className="flex flex-row items-center gap-4 mb-9 justify-center">
              <div className="w-12">
                <img src={edec} alt="" />
              </div>
              <div className="text-4xl font-bold uppercase">
                Le cabinet <span className="text-green-700">EDEC</span>
              </div>
            </div>

            {/* Vision */}
            <div className="w-full flex flex-col md:flex-row justify-between lg:grid-cols-2 gap-9 bg-green-600 ">
              {/* Image section */}
              <div className="p-12 w-full md:w-2/5 bg-green-900 flex flex-col items-center justify-center">
                <div
                  className="w-78 border-4 bg-white drop-shadow-md border-white"
                  style={{ overflowX: "hidden" }}
                >
                  <img
                    src={real1}
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  />
                </div>
              </div>

              <div className="w-full md:w-3/5 flex flex-col mb-4">
                {/* Presentation Description */}
                <div className="flex flex-col gap-4 px-12 pt-12">
                  <span className="text-green-900 font-bold md:text-4xl">
                    A PROPOS DE NOUS
                  </span>
                  <div>
                    <p className="font-medium text-justify text-black">
                      Environment Development Engineering Consulting (EDEC) est
                      une Société à Responsabilité Limitée (SARL) de droit
                      commun camerounais créée en Décembre 2014 et agréée par
                      le:
                    </p>
                    <ul className="list-none mt-4 flex flex-col gap-2">
                      <li className="cursor-pointer">
                        <div className="flex flex-row gap-2 items-start">
                          <FaChevronRight
                            size={15}
                            color="green"
                            className="mt-1"
                          />
                          <span>
                            Ministère de l’Environnement, de la Protection de la
                            Nature et du développement durable (MINEPDED) pour
                            la réalisation des évaluations environnementales ;
                          </span>
                        </div>
                      </li>
                      <li className="cursor-pointer">
                        <div className="flex flex-row gap-2 items-start">
                          <FaChevronRight
                            size={15}
                            color="green"
                            className="mt-1"
                          />
                          <span>
                            Ministère des Mines, de l’Industrie et du
                            Développement Technologique (MINMIDT) pour la
                            réalisation des Etudes de Dangers et Plans d’Urgence
                            ;
                          </span>
                        </div>
                      </li>
                      <li className="cursor-pointer">
                        <div className="flex flex-row gap-2 items-start">
                          <FaChevronRight
                            size={15}
                            color="green"
                            className="mt-1"
                          />
                          <span>
                            Ministre de l'Emploi et de la Formation
                            Professionnelle pour les Formations QHSE.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Vision Description */}
                <div className="flex flex-col gap-4 px-12 pt-8">
                  <span className="text-green-900 font-bold md:text-4xl">
                    NOTRE VISION
                  </span>
                  <div className="font-medium text-justify">
                    La vision stratégique d’EDEC est « Être un Cabinet
                    d’Ingénierie  d’excellence offrant des prestations
                    de qualité en matière d’études, conseils, audits et
                    formation  »
                  </div>
                </div>

                {/* Mission Description */}
                <div className="flex flex-col gap-4 px-12 pt-8">
                  <span className="text-green-900 font-bold md:text-4xl">
                    NOTRE MISSION
                  </span>
                  <div className="font-medium text-justify mb-6">
                    EDEC s’est assignée pour mission de contribuer activement à
                    l’émergence d’un dévéloppement durable dans la sous region  en
                    apportant son expertise dans ses domaines d’intervention.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Partner />
      </div>
    </Layout>
  );
}

export default Presentation;
