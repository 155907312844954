import React from "react";
import Layout from "../../Partials/Layout/Layout";
import real1 from "../../assets/images/real1a.jpg";
import real2 from "../../assets/images/real2.png";
import real3 from "../../assets/images/real3.jpg";
import real4 from "../../assets/images/real4.jpg";
import real5 from "../../assets/images/real5.png";
import real6 from "../../assets/images/real6.jpg";
import real7 from "../../assets/images/real7.jpg";
import real8 from "../../assets/images/real8.jpg";
import real9 from "../../assets/images/real9.jpg";
import real10 from "../../assets/images/real10.jpg";
import real11 from "../../assets/images/real11.jpg";
import real12 from "../../assets/images/real12.jpg";
import real13 from "../../assets/images/real13.jpg";
import real14 from "../../assets/images/real14.jpg";
import real15 from "../../assets/images/real15.jpg";
import real16 from "../../assets/images/real16.jpg";
import real18 from "../../assets/images/real18.jpg";
import real19 from "../../assets/images/real19.jpg";
import real20 from "../../assets/images/real20.jpg";
import real21 from "../../assets/images/real21.jpg";
import real22 from "../../assets/images/real22.jpg";
import real23 from "../../assets/images/real23.jpg";
import real24 from "../../assets/images/real24.jpg";
import real25 from "../../assets/images/real25.jpg";
import real26 from "../../assets/images/real26.jpg";
import real27 from "../../assets/images/real27.jpg";
import real28 from "../../assets/images/real28.jpg";
import real29 from "../../assets/images/real29.jpg";
import real30 from "../../assets/images/real30.jpg";
import real31 from "../../assets/images/real31.jpg";
import real35 from "../../assets/images/real35.jpg";
import real36 from "../../assets/images/real36.jpg";
import real37 from "../../assets/images/real37.jpg";
import real38 from "../../assets/images/real38.jpg";
import real39 from "../../assets/images/real39.jpg";
import real42 from "../../assets/images/real42.jpg";
import Title from "../../Component/Title";
import { Link } from "react-router-dom";
import "./Realisation.css";
import Partner from "../../Section/Partner/Partner";
import { slice } from "lodash";
import { useState } from "react";

// Light Gallery
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

function Realisation() {
  const onInit = () => {
    console.log("");
  };
  const [realisations, setRealisations] = useState([
    {
      id: 1,
      entreprise: "	BRASAF",
      activity: "Agroalimentaire",
      title:
        "Etude d’impact environnemental et social détaillée du projet de construction des Brasseries Samuel FOYOU à Douala",
      images: [
        {
          id: 1,
          image: real1,
          description: "Réunion de clôture des consultations publiques",
        },
        {
          id: 2,
          image: real2,
          description: "Visite du site par les riverains",
        },
      ],
    },
    {
      id: 2,
      entreprise: "BRASAF",
      activity: "Agroalimentaire",
      title:
        "	Etude de dangers et plan d’urgence des Brasseries Samuel FOYOU à Douala",
      images: [
        {
          id: 1,
          image: real3,
          description: "Visite du site et collecte des données",
        },
        {
          id: 2,
          image: real4,
          description: "",
        },
      ],
    },
    {
      id: 3,
      entreprise: "CICC",
      activity: "Agroalimentaire",
      title:
        "Formation du personnel du Conseil Interprofessionnel du Cacao et du Café aux soins de premiers secours.",
      images: [
        {
          id: 1,
          image: real5,
          description: "",
        },
        {
          id: 2,
          image: real6,
          description: "",
        },
        {
          id: 3,
          image: real7,
          description: "",
        },
        {
          id: 4,
          image: real8,
          description: "",
        },
      ],
    },
    {
      id: 4,
      entreprise: "SOLEIL Cameroun",
      activity: "Plasturgie",
      title: "",
      images: [
        {
          id: 1,
          image: real9,
          description: "",
        },
        {
          id: 2,
          image: real10,
          description: "Visite du site par riverains et collecte des données ",
        },
        {
          id: 3,
          image: real11,
          description: "",
        },
        {
          id: 4,
          image: real12,
          description: "Réunion de clôture des consultations publiques",
        },
      ],
    },
    {
      id: 5,
      entreprise: "Routd’Af",
      activity: "BTP",
      title: "",
      images: [
        {
          id: 1,
          image: real16,
          description: "Sensibilisation de la population",
        },
        {
          id: 3,
          image: real15,
          description: "",
        },
        {
          id: 4,
          image: real16,
          description: "Réunion de clôture des consultations publiques",
        },
        {
          id: 5,
          image: real18,
          description: "Réunion de clôture des consultations publiques",
        },
        {
          id: 6,
          image: real19,
          description: "Réunion de clôture des consultations publiques",
        },
      ],
    },
    {
      id: 78,
      entreprise: "	Routd’Af ",
      activity: "BTP",
      title: "Construction des ouvrages",
      images: [
        {
          id: 1,
          image: real20,
          description: "",
        },
      ],
    },
    {
      id: 6,
      entreprise: "	Routd’Af ",
      activity: "BTP",
      title: "Retrocession du materiel Hygiene et Salubrité",
      images: [
        {
          id: 1,
          image: real23,
          description: "",
        },
      ],
    },
    {
      id: 7,
      entreprise: "Routd’Af ",
      activity: "BTP",
      title: "Séminaire de formation",
      images: [
        {
          id: 1,
          image: real21,
          description: "",
        },
        {
          id: 2,
          image: real22,
          description: "	Rétrocession dons ",
        },
      ],
    },
    {
      id: 8,
      entreprise: "Mairie de la ville de Nkongsamba ",
      activity: "Administration",
      title:
        "	notice d’impact environnemental du projet de construction du complexe commercial Phase I de Nkongsamba ",
      images: [
        {
          id: 1,
          image: real24,
          description: "Visite du site et collecte des données",
        },
        {
          id: 2,
          image: real25,
          description: "Entretien avec le Maire de la Ville de Nkongsamba ",
        },
      ],
    },
    {
      id: 9,
      entreprise: "Hysacam  ",
      activity: "Assainissement",
      title:
        "	Audit environnemental et social des installations et activités de l’Agence Hysacam de la ville d’Edéa",
      images: [
        {
          id: 1,
          image: real26,
          description: "Collecte des données",
        },
        {
          id: 2,
          image: real27,
          description: "Entretien avec le maire de la commune d’Edéa 1er  ",
        },
        {
          id: 3,
          image: real28,
          description: "Entretien avec le Sous-préfet d’Edéa 2ème ",
        },
        {
          id: 4,
          image: real29,
          description: "Réunion d’information des populations ",
        },
      ],
    },
    {
      id: 10,
      entreprise: "Ibis hôtel   ",
      activity: " Tourisme",
      title: "	Audit environnemental et social des installations et activités",
      images: [
        {
          id: 1,
          image: real30,
          description: "Entretien avec le délégué départemental du MINTOUL",
        },
        {
          id: 2,
          image: real31,
          description: "Entretien à la chefferie du Canton Bell  ",
        },
      ],
    },
    {
      id: 11,
      entreprise: "Complexe TRESOR hôtel   ",
      activity: "	Tourisme",
      title: "	",
      images: [
        {
          id: 1,
          image: real35,
          description:
            "Intervention du Sous-Préfet à la réunion de clôture des consultations publiques",
        },
        {
          id: 2,
          image: real36,
          description: "  ",
        },
      ],
    },
    {
      id: 12,
      entreprise: "HALLIBURTON   ",
      activity: "Parapétrolière",
      title: "audit environnemental et social	",
      images: [
        {
          id: 1,
          image: real37,
          description: "Visite du site et collecte de données",
        },
        {
          id: 2,
          image: real38,
          description: "",
        },
        {
          id: 3,
          image: real39,
          description: " Visite du site par les riverains ",
        },
        {
          id: 4,
          image: real42,
          description: " Réunion de clôture des consultations publiques ",
        },
      ],
    },
  ]);

  const [initialrealisation, setInitialRealisation] = useState(
    slice(realisations, 0, 3)
  );
  const [selectedactivity, setSelectedActivity] = useState(null);
  const activities = [
    {
      id: 0,
      description: "Tous",
    },
    {
      id: 1,
      description: "Agroalimentaire",
    },
    {
      id: 2,
      description: "Plasturgie",
    },
    {
      id: 3,
      description: "BTP",
    },

    {
      id: 4,
      description: "Administration",
    },

    {
      id: 5,
      description: "Assainissement",
    },

    {
      id: 6,
      description: "Tourisme",
    },

    {
      id: 7,
      description: "Parapétrolière",
    },
  ];
  const filterByActivity = (activity) => {
    const data = realisations.filter(
      (items) => items.activity.trim() === activity.trim()
    );
    setInitialRealisation(data);
  };

  const selectActivity = (active) => {
    setSelectedActivity(active);
    filterByActivity(active);
  };

  return (
    <Layout title="Nos Differentes Réalisations">
      <div className="w-full section container_fluid">
        <Title title="Quelques Réalisations" description="" />
        {/* REALISATION CARD */}
        <div className="w-full py-5 md:flex flex-col items-center justify-center ">
          {/* Acitivity Dropdown list on mobileview */}
          <select
            name=""
            id=""
            value={selectedactivity}
            className="flex flex-col lg:hidden mx-auto md:w-2/5 w-3/5 border p-3 cursor-pointer outline-none"
            onChange={(e) => selectActivity(e.target.value)}
          >
            {activities.map((item) => {
              return (
                <option
                  value={item.description}
                  key={item.id}
                  className="p-3 cursor-pointer"
                >
                  {item.description}
                </option>
              );
            })}
          </select>
          {/* End dropdown */}
          {/* Acitivities List  */}
          <div className="grid grid-cols-7 lg:flex flex-row hidden  gap-3">
            {activities.map((item) => {
              return (
                <Link
                  onClick={() => selectActivity(item.description)}
                  className={`border px-3 py-2 text-center focus:bg-green-600 focus:text-white ${
                    selectedactivity === item.description
                      ? "bg-green-600"
                      : "bg-white"
                  }  `}
                  key={item.id}
                >
                  {item.description}
                </Link>
              );
            })}
          </div>
          {/* ENd Activity list */}
          {/* List des Realisations */}
          <div className="w-full py-10 grid grid-cols-1 md:grid-cols-2 gap-4">
            {initialrealisation.length > 0
              ? initialrealisation.map((realisation) => {
                  return (
                    <div
                      key={realisation.id}
                      className="grid gap-3 border-[10px] border-gren-600 drop-shadow-sm rounded-lg p-1 "
                    >
                      <h3 className="text-3xl font-semibold flex justify-between pr-4">
                        {realisation.entreprise}
                        <span className="text-[7px] text-green-600">
                          {realisation.activity}
                        </span>
                      </h3>

                      {/* ################### Details */}
                      <div className="grid gap-3">
                        <p className="text-black"> {realisation.title}</p>
                        <div className="w-full grid md:grid-cols-2 gap-10">
                          {realisation.images.map((img) => {
                            return img.id <= 2 ? (
                              <div
                                key={img.id}
                                className="   cursor-pointer relative  "
                              >
                                <div className="w-75 h-44 overflow-hidden">
                                  <LightGallery
                                    onInit={onInit}
                                    speed={500}
                                    plugins={[lgThumbnail, lgZoom]}
                                  >
                                    <img
                                      src={img.image}
                                      alt=""
                                      className="hover:scale-125 duration-100 w-full h-full object-cover object-center "
                                    />
                                  </LightGallery>
                                </div>
                                <div className="h-1/5 mt-3">
                                  <h5 className="text-red-500 z-3">
                                    {img.description}
                                  </h5>
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                      {/* ################### End Details */}
                    </div>
                  );
                })
              : slice(realisations, 0, 5).map((realisation) => {
                  return (
                    <div
                      key={realisation.id}
                      className="grid gap-3 border-[10px] border-gren-600 drop-shadow-sm rounded-lg p-1"
                    >
                      <h3 className="text-3xl font-semibold flex justify-between pr-4">
                        {realisation.entreprise}
                        <span className="text-[7px]">
                          {realisation.activity}
                        </span>
                      </h3>

                      {/* ################### Details */}
                      <div className="grid gap-3">
                        <p className="text-black"> {realisation.title}</p>
                        <div className="w-full grid md:grid-cols-2 gap-10">
                          {realisation.images.map((img) => {
                            return img.id <= 2 ? (
                              <div
                                key={img.id}
                                className="   cursor-pointer relative  "
                              >
                                <div className="w-75 h-44 overflow-hidden">
                                  <LightGallery
                                    onInit={onInit}
                                    speed={500}
                                    plugins={[lgThumbnail, lgZoom]}
                                  >
                                    <img
                                      src={img.image}
                                      alt=""
                                      className="hover:scale-125 duration-100 w-full h-full object-cover object-center "
                                    />
                                  </LightGallery>
                                </div>
                                <div className="h-1/5 mt-3">
                                  <h5 className="text-red-500 z-3">
                                    {img.description}
                                  </h5>
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                      {/* ################### End Details */}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <Partner />
    </Layout>
  );
}

export default Realisation;
