import React from "react";
import "./Notfound.css";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

function Notfound() {
  return (
    <div className="notfound">
      <div>
        <Marquee behavior="right" direction="right">
          Page Introuvable
        </Marquee>
        <div className="content_message">
          <h3 className="font-semibold">
            ERREUR <br /> 404 !
          </h3>
          <Link to="/">- Retour à L'Accueil -</Link>
        </div>
      </div>
    </div>
  );
}

export default Notfound;
